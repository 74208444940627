<template>
  <div class="my">
    <div class="banner"></div>
    <div class="my-content">
      <div class="content-list">
        <div class="content-item" @click="goRecord">
          <div class="item-icon">
            <img src="./images/bill.png" />
          </div>
          <div class="item-content">消费记录</div>
          <div class="item-right-icon">
            <img src="./images/arrowForward.png" />
          </div>
        </div>
        <div class="content-item" @click="goMyBenefits">
          <div class="item-icon">
            <img src="./images/crown.png" />
          </div>
          <div class="item-content">会员权益</div>
          <div class="item-right-icon">
            <img src="./images/arrowForward.png" />
          </div>
        </div>
        <a class="content-item" :href="`tel:${tel}`">
          <!-- <div @click="openServiceTel"> -->
          <div class="item-icon">
            <img src="./images/cs.png" />
          </div>
          <div class="item-content">联系客服</div>
          <div class="item-right-icon">
            <img src="./images/arrowForward.png" />
          </div>
          <!-- </div> -->
        </a>
        <div class="content-item" @click="logout">
          <div class="item-icon">
            <img src="./images/exit.png" />
          </div>
          <div class="item-content">退出登录</div>
          <div class="item-right-icon">
            <img src="./images/arrowForward.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="my-card">
      <div class="my-card-1">
        <div class="user-head">
          <img src="./images/userFace.png" alt="头像" />
        </div>
        <div class="user-name">
          {{ (userInfo.accountName || "" + userInfo.id || "" ) || "用户1" }}
        </div>
        <!-- <div class="user-badge"><img src="./images/badge1.png" /></div> -->
        <div class="user-vip">
          会员等级{{ userInfo.accountLevel || 1 }}<img src="./images/vip.png" />
        </div>
      </div>
      <div class="my-card-2">
        <div class="my-card-2-1">
          <div class="my-card-2-common">
            <div class="my-card-2-top">{{ userInfo.jindianAmount || 0 }}</div>
            <div class="my-card-2-bottom">金点</div>
          </div>
        </div>
        <div class="my-card-2-2">
          <div class="my-card-2-common">
            <div class="my-card-2-top">{{ userInfo.couponAmount || 0 }}</div>
            <div class="my-card-2-bottom">优惠券</div>
          </div>
        </div>
        <div class="my-card-2-3">
          <div class="my-card-2-common">
            <div class="my-card-2-top">{{ userInfo.cardCount || 0 }}</div>
            <div class="my-card-2-bottom">激活卡片</div>
          </div>
        </div>
      </div>
      <!-- <div class="my-card-3">
        <div class="my-card-3-1"></div>
        <div class="my-card-3-2">
          <div class="my-card-3-2-1">
            <div style="flex: 1">集勋章兑现金</div>
            <div>已收集 {{ userInfo.xingfudouAmount || 0 }}个</div>
          </div>
          <div class="my-card-3-2-2">
            <div class="my-card-progress">
              <div
                class="my-card-progress-value"
                :style="{ width: process + '%' }"
              ></div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <nav-footer></nav-footer>
  </div>
</template>
<script>
import NavFooter from "@/components/NavFooter";
import { getUserInfo, getServiceTel, logout, getBenefitUrl } from "@/service";
export default {
  components: {
    NavFooter,
  },
  data() {
    return {
      tel: "",
      benefitListUrl: "",
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo || {};
    },
    process() {
      return ((this.userInfo.xingfudouAmount || 0) / 10000) * 100;
    },
  },
  mounted() {
    const merchantId = this.$store.state.merchantId;
    getUserInfo(merchantId);
    this.getServiceTel();
    this.getBenefitUrl();
  },
  methods: {
    async getServiceTel() {
      const { data: tel } = await getServiceTel();
      this.tel = tel;
    },
    openServiceTel() {
      const tel = this.tel;
      this.$modal.info({
        title: "客服电话",
        class: "service-tel-dialog",
        okText: "关闭",
        content: <a href={`tel:${tel}`}>{tel || "--"}</a>,
      });
    },
    async getBenefitUrl() {
      const merchantId = this.$store.state.merchantId;
      const { data } = await getBenefitUrl(merchantId);
      this.benefitListUrl = (data || {}).benefitListUrl || "";
    },
    goRecord() {
      this.$router.push({ name: "consumptionRecord" });
    },
    goMyBenefits() {
      if (!this.benefitListUrl) {
        this.$message.error("未获取到权限地址！");
        return;
      }
      window.location.href = this.benefitListUrl;
    },
    goCService() {
      this.$message.error("敬请期待！");
    },
    goAddress() {
      this.$message.error("敬请期待！");
    },
    async logout() {
      await logout();
      localStorage.removeItem("redirectUrl");
      this.$router.push({ name: "Index" });
    },
  },
};
</script>
<style lang="scss">
.service-tel-dialog {
  .ant-modal {
    width: 3rem !important;
  }
}
</style>
<style lang="scss" scoped>
.my {
  height: 100%;
  position: relative;
  .my-card {
    position: absolute;
    width: 3.57rem;
    height: 1.76rem;
    background-image: url(./images/cardBg.png);
    color: #e9d3a1;
    top: 1.1rem;
    padding: 0.15rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    @include common-bg;
    .my-card-1 {
      display: flex;
      .user-head {
        img {
          width: 0.3rem;
          height: 0.3rem;
        }
      }
      .user-name {
        font-size: 0.16rem;
        margin-left: 0.08rem;
        line-height: 0.32rem;
        flex: 1;
        font-weight: 600;
        @include text-overflow;
      }
      .user-badge {
        margin-left: 0.05rem;

        overflow: hidden;
        white-space: nowrap;
        img {
          width: 0.12rem;
          height: 0.12rem;
        }
      }
      .user-vip {
        font-size: 0.09rem;
        background-color: #e9d3a1;
        color: #1f1f1f;
        height: 0.18rem;
        line-height: 0.18rem;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 0 0.06rem;
        border-radius: 0.1rem;
        margin-left: 0.05rem;
        margin-top: 0.06rem;
        img {
          width: 0.16rem;
          height: 0.16rem;
        }
      }
    }
    .my-card-2 {
      display: flex;
      padding: 0.2rem 0.1rem;
      & > div {
        flex: 1;
      }
      .my-card-2-1 {
        & > div {
          float: left;
        }
      }
      .my-card-2-2 {
        & > div {
          margin: 0 auto;
        }
      }
      .my-card-2-3 {
        & > div {
          float: right;
        }
      }
      .my-card-2-common {
        width: max-content;
        text-align: center;
      }
      .my-card-2-top {
        font-size: 0.19rem;
        max-width: 1rem;
        @include text-overflow;
      }
      .my-card-2-bottom {
        font-size: 0.1rem;
      }
    }
    .my-card-3 {
      display: flex;
      .my-card-3-1 {
        background-image: url(./images/group.png);
        width: 0.35rem;
        height: 0.28rem;
        background-size: 170%;
        background-position: center;
        background-repeat: no-repeat;
      }
      .my-card-3-2 {
        flex: 1;
        margin-right: 0.3rem;
        margin-left: 0.05rem;
        flex-direction: row;
        .my-card-3-2-1 {
          font-size: 0.1rem;
          display: flex;
        }
        .my-card-3-2-2 {
          margin-top: 0.05rem;
          .my-card-progress {
            height: 0.1rem;
            background-color: #4e4e4e;
            border-radius: 0.1rem;

            .my-card-progress-value {
              background-color: #d2a46c;
              width: 0%;
              height: 100%;
              border-radius: 0.1rem;
              position: relative;
              transition: all 1s ease 1.5s;
              &::before {
                content: "";
                width: 0.15rem;
                height: 0.15rem;
                background-image: url(./images/processIcon.png);
                position: absolute;
                right: -0.075rem;
                top: -0.025rem;
                @include common-bg;
              }
            }
          }
        }
      }
    }
  }
  .my-content {
    .content-list {
      margin-top: 1.5rem;
      padding-bottom: 1rem;
      .content-item {
        display: flex;
        padding: 0.15rem 0.2rem;
        border-bottom: 1px solid #ebebeb;
        &:last-child {
          border-bottom: none;
        }
        .item-icon {
          img {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
        .item-right-icon {
          img {
            width: 0.2rem;
            height: 0.2rem;
          }
        }
        .item-content {
          font-size: 0.14rem;
          color: #4d4d4d;
          margin-left: 0.18rem;
          flex: 1;
          font-weight: 600;
          padding-top: 0.03rem;
        }
      }
    }
  }
  .banner {
    height: 2.04rem;
    background-image: url(./images/bg.png);
    @include common-bg;
  }
}
</style>
